import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'
// import { parseRouteArray } from '@/utils/route'
import store from '@/store'

// import Layout from '@/layout'

export const constantRoutes = [

    {
        path: '/',
        name: 'Home',
        desc: '首页',
        component: Layout, // 假设Layout是必需的，用于包裹首页内容
        redirect: '/index', // 保持重定向到'/index'，但实际没有定义子路由了
    },
    {
        path: '/product',
        name: 'Product',
        desc: '产品',
        component: Layout, // 假设Layout是必需的，用于包裹首页内容
        redirect: '/product', // 保持重定向到'/index'，但实际没有定义子路由了
    },
    {
        path: '/ad',
        name: 'Ad',
        desc: '广告',
        component: Layout, // 假设Layout是必需的，用于包裹首页内容
        redirect: '/ad', // 保持重定向到'/index'，但实际没有定义子路由了
    },
    {
        path: '/aboutUs',
        name: 'AboutUs',
        desc: '关于',
        component: Layout, // 假设Layout是必需的，用于包裹首页内容
        redirect: '/aboutUs', // 保持重定向到'/index'，但实际没有定义子路由了
    },
    {
        path: '/',
        name: 'Home',
        desc: '首页',
        component: Layout,
        redirect: '/index',
        children: [
            {
                path: 'index',
                name: 'Index',
                desc: '首页详情',
                component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
            }
        ]
    },
    {
        path: '/product',
        name: 'Product',
        desc: '产品',
        component: Layout,
        redirect: '/product/detail',
        children: [
            {
                path: 'detail',
                name: 'ProductDetail',
                desc: '关于详情',
                component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue')
            }
        ]
    },
    {
        path: '/ad',
        name: 'Ad',
        desc: '广告',
        component: Layout,
        redirect: '/ad/detail',
        children: [
            {
                path: 'detail',
                name: 'AdDetail',
                desc: '关于详情',
                component: () => import(/* webpackChunkName: "about" */ '../views/Ad.vue')
            }
        ]
    },
    {
        path: '/aboutUs',
        name: 'AboutUs',
        desc: '关于',
        component: Layout,
        redirect: '/aboutUs/detail',
        children: [
            {
                path: 'detail',
                name: 'AboutUsDetail',
                desc: '关于详情',
                component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
            }
        ]
    },
    {
        path: '/nest',
        name: 'Nest',
        component: Layout,
        desc: '嵌套测试',
        // 如果有子路由必须声明重定向，并且当前 component 中必须有 <router-view />
        redirect: '/nest/one/one',
        children: [
            {
                path: 'one',
                name: 'NestOne',
                desc: '嵌套1',
                redirect: '/nest/one/one',
                component: () => import('@/views/nest/NestOne'),
                children: [
                    {
                        path: 'one',
                        name: 'NestOneOne',
                        desc: '嵌套1-1',
                        component: () => import('@/views/nest/NestOneOne'),
                    },
                    {
                        path: 'two',
                        name: 'NestOneTwo',
                        desc: '嵌套1-2',
                        component: () => import('@/views/nest/NestOneTwo'),
                    },
                ],
            },
            {
                path: 'two',
                name: 'NestTwo',
                desc: '嵌套2',
                component: () => import('@/views/nest/NestTwo'),
            }
        ],
    },

]

let copyArray = JSON.parse(JSON.stringify(constantRoutes));

store.dispatch(('routes/setRoutes'), copyArray);
// let array = parseRouteArray(copyArray);
// console.log('constantRoutes ===> ', array);

const router = createRouter({
    history: createWebHashHistory(),
    routes: constantRoutes
})



export default router
