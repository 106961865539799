<template>
  <!-- 无嵌套子导航栏 -->
  <a-menu-item
    @click.capture="gotoView(routeObj.path)"
    v-if="!routeObj.children"
  >
    {{ routeObj.desc || routeObj.name }}
  </a-menu-item>

  <!-- 嵌套子导航栏 -->
  <!-- <a-sub-menu
    :key="routeObj.path"
    v-if="routeObj.children"
    @titleClick="gotoView(routeObj.path)"
    :title="routeObj.desc || routeObj.name"
  >
    <head-nav-item
      :route-obj="route"
      :v-if="routeObj.children"
      v-for="route in routeObj.children"
      :key="route.path"
      :base-path="getFullPath(routeObj.path)"
    />
  </a-sub-menu> -->
</template>

<script>
/**
 * 头部导航栏部件
 */
export default {
  name: "HeadNavItem",
  components: {},
  props: {
    // route object
    routeObj: {
      type: Object,
      required: true,
    },

    basePath: {
      type: String,
      default: "",
    },
  },
  methods: {
    gotoView(path) {
      // console.log('跳转 ===> ', path);
      // 完整的地址
      let fullPath = this.getFullPath(path);
      console.log("HeadNavItem 跳转 ===> ", fullPath);
      this.$router.push(fullPath);
    },
    getFullPath(path) {
      return this.basePath + (path.indexOf("/") === 0 ? path : "/" + path);
      // console.log('getFullPath ===> ', s);
      // return s;
    },
  },
};
</script>

<style scoped>
</style>