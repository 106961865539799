<template>
  <div data-v-109a143a="" class="relative w-full">
    <header class="header" data-v-109a143a="">
      <div
        class="flex justify-between items-center h-full mx-auto max-w-7xl"
        data-v-109a143a=""
      >
        <div
          class="flex items-center flex-none justify-center lg:justify-start lg:flex-1"
          data-v-109a143a=""
        >
          <a
            href="/"
            aria-current="page"
            aria-label="homeLink"
            class="nuxt-link-exact-active nuxt-link-active w-130px lg:w-210px"
            data-v-109a143a=""
          >
            <img
              alt="Vue logo"
              class="baichenimg"
              src="@/assets/新百宸LOGO1.png"
          /></a>
        </div>
        <a-menu mode="horizontal" theme="light">
          <head-nav-item
            v-for="route in routes"
            :key="route.path"
            :route-obj="route"
          />
        </a-menu>
        <div class="flex items-center justify-end lg:flex-1" data-v-109a143a="">
          <div class="hidden lg:block flex-shrink-0" data-v-109a143a="">
            <el-button plain @click="login()">登录</el-button>
            <el-button type="primary" @click="gratis()">注册</el-button>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
// import store from '@/store'
import { mapGetters } from "vuex";
import HeadNavItem from "@/layout/head-nav/HeadNavItem";
// import { constantRoutes } from '@/router'

/**
 * 头部导航栏
 */
export default {
  name: "HeadNav",
  components: { HeadNavItem },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      routes: "routes/routes",
    }),
  },
  created() {
    // console.log('head-nav created ====> ', this.routes);
    // 路由初始化
    /*if (this.routes.length === 0) {
      store.dispatch(('routes/setRoutes'), constantRoutes);
    }*/
  },
  methods: {
    /*gotoView(path){
      console.log('跳转 ===> ',path)
      this.$router.push(path)
    }*/
    login() {
      window.open("http://erp.insmeta.net/");
    },
    gratis() {
      window.open("http://erp.insmeta.net/register");
    },
  },
  mounted() {
    // console.log(JSON.stringify(this.routes))
    console.log("$router ===> ,", this.$router.getRoutes());
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .header[data-v-109a143a] {
    height: 4rem;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .lg\:flex-1 {
    flex: 1 1 0%;
  }
}
@media (min-width: 1024px) {
  .lg\:justify-start {
    justify-content: flex-start;
  }
}
.header[data-v-109a143a] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  height: 3.75rem;
  padding-left: 15px;
  padding-right: 15px;
  position: sticky;
  top: 0;
  z-index: 50;
  box-shadow: 0 2px 10px 0 rgba(180, 189, 221, 0.25);
  transition: top 0.2s ease;
}
.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  box-shadow: none;
}
.max-w-7xl {
  max-width: 80rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.h-full {
  height: 100%;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
.baichenimg {
  width: 120px;
  padding-bottom: 10px;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.w-full {
  width: 100%;
}
.relative {
  position: relative;
}
</style>