<template>
  <div id="components-layout-demo-basic">
    <a-layout>
      <a-layout-header>
        <head-nav />
      </a-layout-header>

      <a-layout-content>
        <router-view />
      </a-layout-content>

      <a-layout-footer>
        <footer-nav />
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import HeadNav from "@/layout/head-nav/HeadNav";
import FooterNav from "@/layout/head-nav/FooterNav.vue";
export default {
  name: "web-layout",
  components: { HeadNav, FooterNav },
};
</script>

<style>
#components-layout-demo-basic > section {
  height: 100%;
}

#components-layout-demo-basic {
  text-align: center;
  height: 100%;
}
#components-layout-demo-basic .ant-layout-header {
  background: white;
}
#components-layout-demo-basic .ant-layout-footer {
  /*background: #7dbcea;*/
  /*color: #fff;*/
}
#components-layout-demo-basic .ant-layout-footer {
  line-height: 1.5;
}
#components-layout-demo-basic .ant-layout-sider {
  background: #3ba0e9;
  color: #fff;
  line-height: 120px;
}
/*#components-layout-demo-basic .ant-layout-content {*/
/*  background: rgba(16, 142, 233, 1);*/
/*  color: #fff;*/
/*  min-height: 120px;*/
/*  line-height: 120px;*/
/*}*/
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
</style>