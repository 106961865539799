<template>
  <div>
    <div data-v-f11e56ca="" class="w-full footer-bg">
      <div data-v-f11e56ca="" class="footer-banner">
        <p
          data-v-f11e56ca=""
          data-aos="fade-up"
          data-aos-once="true"
          class="footer-title aos-init aos-animate"
        >
          人效全面提升、业绩翻倍增长，从此刻开始！
        </p>
        <p
          data-v-f11e56ca=""
          data-aos="fade-up"
          data-aos-once="true"
          class="footer-description aos-init aos-animate"
        >
          百宸ERP将帮助您提升每一个人员效率，快速实现业务增长
        </p>
        <!---->
        <button :class="['transparent-button']">获取报价</button>
      </div>
    </div>

    <footer
      data-v-779662e1=""
      data-v-109a143a=""
      aria-label="footerHeading"
      class="footer relative z-10"
    >
      <div
        data-v-779662e1=""
        class="flex justify-between text-white max-w-7xl mx-auto py-12 px-[23px] lg:py-20 lg:px-0"
      >
        <div data-v-779662e1="" class="mr-48 hidden lg:block">
          <img
            alt="Vue logo"
            class="baichenimg"
            src="@/assets/新百宸LOGO2.png"
            width="225px"
          />
          <div data-v-779662e1="" class="mt-5">
            <p data-v-779662e1="" class="text-2xl font-medium">
              百原數字科技有限公司
            </p>
          </div>
          <div data-v-779662e1="" class="mt-5">
            <p
              data-v-779662e1=""
              class="font-light text-sm text-white text-opacity-65"
            >
              地址
            </p>
            <p data-v-779662e1="" class="text-2xl font-medium">
              香港新界屯門建泰街6號恆威工業中心地下185鋪
            </p>
          </div>
          <div data-v-779662e1="" class="mt-5">
            <p
              data-v-779662e1=""
              class="font-light text-sm text-white text-opacity-65"
            >
              邮箱
            </p>
            <p data-v-779662e1="" class="text-2xl font-medium">
              adminerp@insmeta.net
            </p>
          </div>
          <div data-v-779662e1="" class="mt-5">
            <p data-v-779662e1="" class="text-2xl font-medium">
              版权所有 ©2022 <span style="color: #888">百宸ERP</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-bg[data-v-f11e56ca] {
  background: linear-gradient(180deg, #2554fe, #3572ff);
}
.w-full {
  width: 100%;
}
@media (min-width: 1024px) {
  .footer-banner[data-v-f11e56ca] {
    padding: 5rem 0;
    background-image: url(https://static.distributetop.com/office-site-new/office-site-upload/img/app-promotion-bg.252059a.png);
    background-size: cover;
  }
}
.footer-banner[data-v-f11e56ca] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 15px;
  background-image: url(https://static.distributetop.com/office-site-new/office-site-upload/img/history-card-sm-bg.cbfbf77.png);
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 100% 5.375rem;
}
@media (min-width: 1024px) {
  .footer-banner .footer-title[data-v-f11e56ca] {
    font-size: 2.25rem;
    line-height: 3.125rem;
    margin-bottom: 0;
  }
}
.footer-banner .footer-title[data-v-f11e56ca] {
  font-weight: 500;
  font-size: 2.25rem;
  margin-bottom: 0.25rem;
  max-width: 80rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  letter-spacing: 2px;
}
@media (min-width: 1024px) {
  .footer-banner .footer-title[data-v-f11e56ca][data-v-fae5bece] {
    font-size: 2.25rem;
    line-height: 3.125rem;
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .footer-banner .footer-description[data-v-f11e56ca] {
    display: block;
  }
}
.footer-banner .footer-description[data-v-f11e56ca] {
  line-height: 1.75rem;
  font-weight: 300;
  font-size: 1.125rem;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.transparent-button {
  background-color: rgba(255, 255, 255, 0.26); /* 半透明白色背景 */
  border: 1px solid white; /* 白色边框 */
  color: white; /* 字体为白色 */
  font-size: 1.125rem;
  transition: all 0.3s; /* 平滑过渡效果 */
  border-radius: 5px; /* 圆角大小，按需调整 */
  padding: 10px 50px; /* 内边距，控制按钮大小 */
}

.transparent-button:hover {
  background-color: white; /* 鼠标悬停时变为纯白色背景 */
  color: blue; /* 字体变为蓝色 */
}
@media (min-width: 1024px) {
  .footer[data-v-779662e1] {
    padding-bottom: 0;
  }
}
.footer[data-v-779662e1] {
  padding-bottom: 3rem;
  width: 100%;
  background: #20222e;
}
.z-10 {
  z-index: 10;
}
.relative {
  position: relative;
}
@media (min-width: 1024px) {
  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 1024px) {
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.max-w-7xl {
  max-width: 80rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.justify-between {
  justify-content: space-between;
}
.flex {
  display: flex;
}
@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }
}
.mr-48 {
  margin-right: 12rem;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
img,
video {
  max-width: 100%;
  height: auto;
}
iframe,
img,
video {
  display: block;
  vertical-align: middle;
}
.mt-5 {
  margin-top: 1.25rem;
}
.text-opacity-65 {
  --tw-text-opacity: 0.65;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-sm {
  font-size: 0.875rem;
}
.font-light {
  font-weight: 300;
  text-align: left;
}
.text-2xl {
  font-size: 1.5rem;
}
.font-medium {
  font-weight: 500;
  text-align: left;
}
dd,
dl,
h2,
h3,
p {
  margin: 0;
}
</style>