/**
 * @description 路由存储
 */
// import { constantRoutes } from '@/router'
// import { getRouterList } from '@/api/router'
// import { filterRouteArray } from '@/utils/route'

const state = () => ({
    routes: []
})
const getters = {
    routes: (state) => state.routes,
    partialRoutes: (state) => state.partialRoutes,
}
const mutations = {
    setRoutes(state, routes) {
        state.routes = routes
    }
}
const actions = {
    /**
     * @author chuzhixin 1204505056@qq.com
     * @description intelligence模式设置路由
     * @param {*} { commit }
     * @returns
     */
    async setRoutes({commit}, routesArray) {
        // console.log('routesArray ===> ', routesArray);
        const finallyRoutes = [...routesArray];
        // filterRouteArray(finallyRoutes);
        // console.log('finallyRoutes ===> ', finallyRoutes);
        commit('setRoutes', finallyRoutes)
    },
}
export default { state, getters, mutations, actions }
